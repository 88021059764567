/* Copyright 2022 Google LLC

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     https://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License. */

* {
  margin: 0;
  padding: 0;
}

.userlist-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
  grid-template-columns: 1fr 1fr;
  gap: 5px 20px;
}

.user-container {
  height: 100px;
  width: 100%;
  display: flex;
  border: 1px solid black;
}

.user-detail {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

button {
  margin-top: 10px;
  border: 2px solid #383636;
  color: #383636;
  background-color: #fff;
  cursor: pointer;
  padding: 10px 20px;
  font-family: Lato, sans-serif;
}

button:hover {
  background-color: #383636;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.error {
  color: red;
}

/* stylelint-disable selector-class-pattern */
.react-pdf__Page.prevPage {
  position: absolute !important;
  z-index: 1;
}
/* stylelint-enable selector-class-pattern */
